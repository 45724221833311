import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm a Senior Software Developer`}</p>
    </blockquote>
    <p>{`I build financial applications using Elixir, Phoenix, Javascript, Typescript and React`}</p>
    <p>{`I am a former U.S. Navy F-14 electrician, commercial pilot, small business owner and congressional aide, spokesman, and speechwriter in both the U.S. Senate and U.S. House of Representatives  🇺🇸   🇪🇨    🏴‍☠️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      